import React, { useState, useEffect } from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../../containers/layout'
import GraphQLErrorList from '../../components/graphql-error-list'
import Carousel from '../../components/carousel'
import PortableText from '../../components/portableText'
import {Formik} from 'formik';
import * as Yup from 'yup';
import {fetchDatafromAPI} from '../../lib/apiHelper'
import ACTIONS from '../../services/actions'
import EMAILTEMPLATES from '../../services/emailTemplates'


export const query = graphql`
    query requestAccessPageQuery {
        pageInfo: sanityRequestAccessPage(_id: {regex: "/(drafts.|)requestAccessPage/"}) {
            _id
            title
            banner {
                asset {
                  url
                }
            }
            _rawBody(resolveReferences: {maxDepth: 5})
        }

        regions: allSanityRegions {
            nodes {
                name
                email
            }
        }
        
    }
`
// query region for dropdown

const RequestAccessPage = props => {
    const {data, errors} = props

    if (errors) {
        return (
        <Layout>
            <GraphQLErrorList errors={errors} />
        </Layout>
        )
    }

    const pageInfo = (data || {}).pageInfo
    if (!pageInfo) {
        throw new Error(
        'Missing page info.'
        )
    }

    let  banner = pageInfo.banner && pageInfo.banner.asset ? pageInfo.banner.asset.url : ''

    const [completeMessage, setCompleteMessage] = useState()

    const regions = (data || {}).regions.nodes || []
    regions.sort((a,b) => a.name.localeCompare(b.name))
    // console.log('--> regions: ', regions)

    return(
        <Layout>
            {/* banner */}
            <div className='container container-adjust-p'>
            {
                banner ? 
                <div>
                    <img src={banner} alt={pageInfo.banner ? pageInfo.banner.alt : ''} className='d-none d-sm-block' style={{margin:'0 auto', width:'100%'}} />
                </div>
                : 
                <div className="bg-brown d-none d-sm-flex" style={{backgroundImage: 'url(' + banner + ')', backgroundPosition: 'center center', backgroundSize: 'cover', width: '100%', height: '180px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                </div>
            }
            <div className="bg-brown d-flex d-sm-none py-4" style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight:100 }}> 
                {/* <div className="container">
                    <div className="row">
                        <h1 className='mb-0' style={{color: 'rgb(245, 235, 220)'}}>{pageInfo.title.toUpperCase() || 'REQUEST ACCESS'}</h1>
                    </div>
                </div> */}
            </div>
            </div>


            {/* breadcrumb */}
            <section className="p-0">
                <div className="container">
                    <div className="row">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Request Access</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </section>


            {/* content */}
            <div className="container">
                <div className="row">

                    {
                        !completeMessage &&
                        <>
                        <div className="col-12 col-md-8" style={{margin: '0 auto'}}>
                            {pageInfo._rawBody && <PortableText blocks={pageInfo._rawBody} />}
                        </div>
                        <div className="col-md-6 mb-8" style={{margin: '0 auto'}}>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={ValidateSchema}
                                onSubmit={async (values, actions) => {
                                    setCompleteMessage(null)

                                    // if pass checking call lambda api
                                    try {
                                        // let apiEndpoint = 'https://j43jfzh1qh.execute-api.us-east-1.amazonaws.com/prod/api'
                                        let apiEndpoint = process.env.NATIVE_USER_API_ENDPOINT
                                        let data = {
                                            action: ACTIONS.REQUEST_ACCESS,
                                            values: values
                                        }

                                        let response = await fetchDatafromAPI(apiEndpoint, data)
                                        // console.log('---> response: ', response)
                                        
                                        if(response.body && response.body.isCreated && response.body.isCreated === true){

                                            // select admin email to send to
                                            let _region = regions.find(region => region.name === values.region)
                                            let adminEmail = (_region && _region.email) ? [_region.email] : ["valerie.vucko@ofitechnology.com"]
                                            
                                            // send email
                                            let emailEndpoint = 'https://zauwx1fjff.execute-api.us-east-1.amazonaws.com/prod/sendmail'
                                            let emailData = {
                                                // "toAddress": ["valerie.vucko@ofitechnology.com", "nichapon.didwisade@ofitechnology.com"],
                                                "toAddress": adminEmail, // only admin email
                                                "fromAddress": "noreply@designwithbk.com",
                                                "subject": "[External] New user has requested access",
                                                "body": EMAILTEMPLATES.REQUEST_ACCESS_TEMPLATE
                                            }
                                            let emailResponse = await fetchDatafromAPI(emailEndpoint, emailData)
                                            // console.log('---> send email: ', emailResponse)

                                            // reset form after success
                                            // setCompleteMessage(response.body.message)
                                            setCompleteMessage('Your user account has been created but has to be approved before it can be used. You will receive an email confirmation once your account has been approved.')
                                            actions.resetForm()

                                        }
                                        else if(response.body && response.body.fieldName === 'email'){
                                            actions.setErrors({email: response.body.message})
                                        }
                                        else{
                                            actions.setErrors({failMessage: response.body.message})
                                        }

                                    } catch (error) {
                                        // console.log("log: create user -> error", error)
                                        actions.setErrors({failMessage: "Form submitting fail, Please try again or contact administrator."})
                                    }

                                }}
                            >
                                {({ values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting, resetForm }) => (                                
                                    <form 
                                        onSubmit={(e) => {
                                            e.preventDefault() // prevent refresh page
                                            handleSubmit()
                                        }}
                                    >

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="email">Email <span className="text-danger">*</span></label>
                                            <input name="email" type="text" className="form-control" id="email" placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                            {errors.email && touched.email ? <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.email}</div> : null}                                        
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="password">Password <span className="text-danger">*</span></label>
                                            <input name="password" type="password" className="form-control" id="password" placeholder="Password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                            {errors.password && touched.password ? <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.password}</div> : null}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="confirmPassword">Confirm Password <span className="text-danger">*</span></label>
                                            <input name="confirmPassword" type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" onChange={handleChange} onBlur={handleBlur} value={values.confirmPassword} />
                                            {errors.confirmPassword && touched.confirmPassword ? <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.confirmPassword}</div> : null}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="firstname">First Name <span className="text-danger">*</span></label>
                                            <input name="firstname" type="text" className="form-control" id="firstname" placeholder="First Name" onChange={handleChange} onBlur={handleBlur} value={values.firstname} />
                                            {errors.firstname && touched.firstname ? <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.firstname}</div> : null}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="lastname">Last Name <span className="text-danger">*</span></label>
                                            <input name="lastname" type="text" className="form-control" id="lastname" placeholder="Last Name" onChange={handleChange} onBlur={handleBlur} value={values.lastname} />
                                            {errors.lastname && touched.lastname ? <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.lastname}</div> : null}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="phoneNumber">Phone Number</label>
                                            <input name="phoneNumber" type="text" className="form-control" id="phoneNumber" placeholder="Phone Number" onChange={handleChange} onBlur={handleBlur} value={values.phoneNumber} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="franchise">Franchisee</label>
                                            <input name="franchise" type="text" className="form-control" id="franchise" placeholder="Franchisee" onChange={handleChange} onBlur={handleBlur} value={values.franchise} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="region">Region <span className="text-danger">*</span></label>
                                            <select name="region" className="form-select" id="region" aria-label="Region" onChange={handleChange} onBlur={handleBlur} value={values.region}>
                                                <option value="">-- Select Region --</option>
                                                {
                                                    regions && regions.map((region, i) => (<option key={i} value={region.name}>{region.name}</option>))
                                                }
                                            </select>
                                            {errors.region && touched.region && <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.region}</div>}
                                        </div>

                                        
                                        {
                                            isSubmitting ? 
                                            <button type="submit" className="btn btn-secondary col-12" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...
                                            </button>
                                            : <button type="submit" className="btn btn-primary col-12">Submit</button>
                                        }

                                        {errors.failMessage && 
                                            <div className="alert alert-danger" role="alert">
                                                <h4 className="alert-heading mb-1">Fail!</h4>
                                                <hr className="mt-1" />
                                                <p>{errors.failMessage}</p>
                                            </div>
                                        }                                    
                                    </form>
                                )}
                            </Formik>
                        </div>
                        </>
                    }

                    {
                        completeMessage &&
                        <div className="col-12 col-md-8 mb-6" style={{margin: '0 auto'}}>
                            <div className="alert alert-success" role="alert">
                                <h4 className="alert-heading mb-1">Successfully!</h4>
                                <hr className="mt-1" />
                                <p>{completeMessage}</p>
                            </div>
                        </div>
                    }

                </div>                
            </div>            

        </Layout>
    )
}

const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    franchise: '',
    region: ''
}

const ValidateSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format.').required('Email is required.'),
    password: Yup.string()
        .required('Password is required.')
        .min(7, "The password must be 7 or more characters and have one each of: upper-case letter, lower-case letter, number and symbol.")
        .matches(
            new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})"),
            "The password must be 7 or more characters and have one each of: upper-case letter, lower-case letter, number and symbol."
        ),
    confirmPassword: Yup.string()
        .required('Confirm password is required.')
        .oneOf([Yup.ref("password"), null], "Password must match confirmation"),
    firstname: Yup.string().required('First Name is required.'),
    lastname: Yup.string().required('Last Name is required.'),
    region: Yup.string().required('Region is required.'),
});

export default RequestAccessPage